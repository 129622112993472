/*
 * @Description:
 * @Author: Devin
 * @Date: 2024-05-27 16:50:35
 */
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import AppRouter from "@/router";

function App() {

  return (
    <>
      <ScrollToTop>
        <AppRouter />
      </ScrollToTop>
    </>
  );
}

export default App;
