/*
 * @Description:
 * @Author: Devin
 * @Date: 2024-02-06 14:10:44
 */
import React, { lazy } from "react";
import Layout from "./Layout";

const routerConfig = [
  { path: "/", name: "home", component: "home" },
  { path: "/home", name: "home", redirect: "/" },

  {
    path: "/contact",
    name: "/contact",
    component: "contact",
    exact: true,
  },
];

const noHeaderRoutes = [
  {
    path: "/login",
    name: "login",
    redirect: "/project/#/login",
    isExternal: true,
  },
  {
    path: "/register",
    name: "register",
    redirect: "/project/#/register",
    isExternal: true,
  },
  {
    path: "/verifyemail/:verifyCode",
    name: "verifyemail",
    redirect: "/project/#/verifyemail/:verifyCode",
    isExternal: true,
  },
  {
    path: "/verifyemail",
    name: "verifyemail",
    redirect: "/project/#/verifyemail",
    isExternal: true,
  },
  {
    path: "/resetpassword/:resetToken",
    name: "forgotpassword",
    redirect: "/project/#/resetpassword/:resetToken",
    isExternal: true,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    redirect: "/project/#/forgotpassword",
    isExternal: true,
  },
];

const renderChild = (routerConfig, config = []) => {
  if (!Array.isArray(routerConfig)) {
    return null;
  }
  routerConfig.map((item) => {
    if (item.childRoutes) {
      config = renderChild(item.childRoutes, config);
    }
    config.push({
      path: item.path,
      name: item?.name && item.name,
      exact: item.exact && item.exact,
      redirect: item.redirect && item.redirect,
      isExternal: item.isExternal && item.isExternal,
      component:
        item.component && lazy(() => import(`@/views/${item.component}`)),
      meta: item.name && { title: item.name },
    });
  });

  return config;
};

const config = [
  {
    path: "/",
    component: Layout,
    childRoutes: [...renderChild(routerConfig)],
  },
  ...renderChild(noHeaderRoutes),
];

export default config;
