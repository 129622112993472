/*
 * @Description:
 * @Author: Devin
 * @Date: 2023-04-06 19:43:24
 */

import { create } from "zustand";
import { persist } from "zustand/middleware";

export const USER_KEY = "user-info";

export const useUserStore = create()(
  persist(
    (set, get) => ({
      user: {},
      accessToken: "",
      updateUser(user) {
        set((state) => ({ user }));
      },
      updateAccessToken(cookie) {
        set((state) => ({ accessToken: cookie }));
      },
    }),
    {
      name: USER_KEY,
      version: 1,
    }
  )
);

export const { updateAccessToken } = useUserStore.getState();
