import React from "react";
import logo from "../images/logo_footer.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import instagram from "../images/instagram.svg";
import dribbble from "../images/dribbble.svg";
import twitter from "../images/twitter.svg";
import youtube from "../images/youtube.svg";
import send from "../images/send.svg";

const Footer = () => {
  return (
    <footer className="bg-footer text-white py-8 md:py-12 lg:py-14 lg:pl-28 lg:pr-36 ">
      <div className="container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-start my-6">
        {/* 第一块 */}
        <div className="flex flex-col md:inline-block  lg:mb-0 lg:flex-1 lg:pl-16 mb-6">
          <div className="flex justify-center md:block items-center text-center font-semibold">
            <HashLink smooth to="/#home">
              <img src={logo} alt="" className="w-28" />
            </HashLink>
          </div>
          <div className="text-md text-white text-center md:text-left leading-loose my-10">
            Copyright &copy; {new Date().getFullYear()}
            <HashLink to="#" className="hover:white-hover">
              &nbsp; arXtect
            </HashLink>
            &nbsp;ltd.<div> All rights reserved.</div>
          </div>
          <div className="mx-auto text-center mt-2">
            <ul className="flex  mb-4 md:mb-0">
              <li>
                <Link
                  to="#"
                  className="rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="instagram"
                >
                  <img src={instagram} alt="" />
                </Link>
              </li>
              <li className="ml-4">
                <Link
                  to="#"
                  className="rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="dribbble"
                >
                  <img src={dribbble} alt="" />
                </Link>
              </li>
              <li className="ml-4">
                <Link
                  to="#"
                  className="rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="twitter"
                >
                  <img src={twitter} alt="" />
                </Link>
              </li>
              <li className="ml-4">
                <Link
                  to="#"
                  className="rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="youtube"
                >
                  <img src={youtube} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* 第二块 */}
        <div className="mb-6 lg:mb-0 lg:w-[16%] hidden md:block">
          <h4 className="font-semibold mb-4 text-xl">Company</h4>
          <ul>
            <li className="pb-3 text-[#f5f7fae0]  hover:text-white">
              <a href="#">Medium:</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">X:</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Discord:</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Telegram:</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Contact us:</a>
            </li>
          </ul>
        </div>
        {/* 第三块 */}
        <div className="mb-6 lg:mb-0 lg:w-[16%] hidden md:block">
          <h4 className="font-semibold mb-4 text-xl">Support</h4>
          <ul>
            <li className="pb-3 text-[#f5f7fae0]  hover:text-white">
              <a href="#">Help center</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Terms of service</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Legal</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Privacy policy</a>
            </li>
            <li className="pb-3 text-[#f5f7fae0] hover:text-white">
              <a href="#">Status</a>
            </li>
          </ul>
        </div>
        {/* 第四块 */}
        <div className="lg:w-[24%] hidden md:block">
          <h4 className="mb-4 text-xl font-semibold">Stay up to date</h4>
          <div className="relative flex items-center mr-8 mt-6">
            <input
              type="email"
              placeholder="Your email address"
              className="bg-[#515b60] w-full py-2 px-4  pr-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="absolute right-3">
              <img src={send} alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
