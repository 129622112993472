/*
 * @Description:
 * @Author: Devin
 * @Date: 2024-07-05 10:20:46
 */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectHandler = ({ to, isExternal }) => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let destination = to;

    // Replace parameters in the destination URL
    Object.keys(params).forEach((key) => {
      destination = destination.replace(`:${key}`, params[key]);
    });

    if (isExternal) {
      window.location.href = destination;
    } else {
      navigate(destination, { replace: true });
    }
  }, [to, isExternal, params, navigate]);

  return null;
};

export default RedirectHandler;
