// Layout.js
import React, { useEffect } from "react";
import AOS from "aos";
import { Outlet } from "react-router-dom";
import { AuthProvider, useAuth } from "@/useHooks"

import Footer from "@/components/Footer";
import NavBar from "@/components/Navbar/NavBar";

import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    aos_init();

    window.addEventListener("load", aos_init);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("load", aos_init);
    };
  }, [location.pathname]); // Re-run the effect when the location pathname changes


  return (
    <AuthProvider>
      <NavBar />
      <main className="main h-[calc(100vh)]">
        <Outlet />
        <Footer />
      </main>
    </AuthProvider>
  );
};

export default Layout;
