import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import { ArxEditorURL } from "@/constants";

const NavLinksLg = () => {
  return (
    <div className="flex justify-between items-center mr-10 ">
      <div className="flex items-center space-x-7">
        <HashLink
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-dark hover:text-blue-900 "
          smooth
          to="/#home"
        >
          Home
        </HashLink>
        <HashLink
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900 "
          smooth
          to="/#about"
        >
          About
        </HashLink>
        <a
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
          smooth
          href={ArxEditorURL}
        >
          Editor
        </a>
        <a
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
          href={`${ArxEditorURL}/#/einstein`}
        >
          Publishing
        </a>
        <HashLink
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
          to="/#social"
          smooth
        >
          Social
        </HashLink>
        <HashLink
          className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900 whitespace-nowrap overflow-hidden text-ellipsis"
          to="/contact/#contact"
          smooth
        >
          Join us
        </HashLink>
      </div>
      <UserMenu></UserMenu>
    </div>
  );
};

const NavLinksSm = () => {
  return (
    <>
      <HashLink
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-dark hover:text-blue-900"
        smooth
        to="/#home"
      >
        Home
      </HashLink>
      <HashLink
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
        smooth
        to="/#about"
      >
        About
      </HashLink>
      <a
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
        smooth
        href={`${ArxEditorURL}/#/project`}
      >
        Editor
      </a>
      <a
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
        href={`${ArxEditorURL}/#/einstein`}
      >
        Publishing
      </a>
      <HashLink
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
        smooth
        to="/#social"
      >
        Social
      </HashLink>
      <HashLink
        className="px-4 flex justify-center text-[1.1rem] font-[600] text-gray-900 hover:text-blue-900"
        to="/contact/#contact"
        smooth
      >
        Join us
      </HashLink>
      <UserMenu></UserMenu>
    </>
  );
};

export { NavLinksLg, NavLinksSm };
